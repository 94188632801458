import './App.css';
import './index.css';
import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  Route,
  // useRouteMatch
} from "react-router-dom";
import Nav from './Nav'
import Banner from './Banner'
import HomeBanner from './HomeBanner'
import DownloadBox from './DownloadBox'
import NationalSecurityLaw from './NationalSecurityLaw'
import MoneyLaundering from './MoneyLaundering'
import DueProcess from './DueProcess'
import LegalAid from './LegalAid'
import Home from './Home'
import Footer from './Footer'
import {
  CssBaseline,
  Container
} from '@material-ui/core'
import { MuiThemeProvider, createMuiTheme, makeStyles } from '@material-ui/core/styles'
import MeshGradient from './assets/mesh-gradient.png'

function App() {
  const classes = useStyles()

  return (
    <MuiThemeProvider theme={Theme}>
      <Router>
        <CssBaseline />
        <Switch>
          <Route exact path="/">
            <Redirect to="/zh/home" />
          </Route>
          <Route exact path="/:lang/home">
            <div className={classes.headerBackgroundHome}>
              <img className={classes.heroBackground} src={MeshGradient} />
              <Nav backgroundColor="transparent" />
              <HomeBanner />
            </div>
            <Home />
          </Route>
          <Route exact path={'/:lang/national-security-law/:section?'}>
            <div className={classes.headerBackgroundInner}>
              <Nav backgroundColor="#ED882B" />
              <Banner />
            </div>
            <Container maxWidth="xl">
              <NationalSecurityLaw />
            </Container>
          </Route>
          <Route exact path={'/:lang/money-laundering/:section?'}>
            <div className={classes.headerBackgroundInner}>
              <Nav backgroundColor="#3F82E0" />
              <Banner />
            </div>
            <Container maxWidth="xl">
              <MoneyLaundering />
            </Container>
          </Route>
          <Route exact path={'/:lang/legal-aid/:section?'}>
            <div className={classes.headerBackgroundInner}>
              <Nav backgroundColor="#6E77CC" />
              <Banner />
              <DownloadBox />
            </div>
            <Container maxWidth="xl">
              <LegalAid />
            </Container>
          </Route>
          <Route exact path={'/:lang/due-process/:section?'}>
            <div className={classes.headerBackgroundInner}>
              <Nav backgroundColor="#2E9999" />
              <Banner />
              <DownloadBox />
            </div>
            <Container maxWidth="xl">
              <DueProcess />
            </Container>
          </Route>
        </Switch>
        <Footer />
      </Router>
    </MuiThemeProvider>
  );
}

const useStyles = makeStyles((theme) => ({
  heroBackground: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: '100%',
    maxWidth: '100%',
    width: '100%',
    objectFit: 'cover',
    position: 'absolute',
    zIndex: -1
  },
  funnyCircle: {
    background: 'linear-gradient(30.85deg, #DC6161 10.09%, #F59999 82.65%)',
    opacity: 0.5,
    borderRadius: '50%',
    position: 'relative',
    [theme.breakpoints.down('sm')]:{
      width: '830px',
      left: '46%',
      top: '-106px',
      height: '830px'
    },
    [theme.breakpoints.up('md')]:{
      width: '1165px',
      height: '1247.11px',
      left: '46%',
      top: '-237.13px'
    }
  },
  funnyCircleContainer: {
    position: 'absolute',
    width: '100%',
    height: '550px',
    overflow: 'hidden'
  },
  headerBackground: {
    height: '550px',
    background: 'transparent',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start'
    }
  },
  headerBackgroundHome: {
    position: 'relative',
    // background: '#D84937',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start'
    }
  },
  headerBackgroundInner: {
    background: '#31323A',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start'
    }
  }
}))

const Theme = createMuiTheme({
  typography: {
    fontFamily: "Manrope, sans-serif"
  }
})

export default App;
