import React, { useState } from 'react'
import {
  Card,
  CardContent,
  Container,
  Grid,
  Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import MainBody from './MainBody'
import nationalSecurityLawObj from "./content/national-security-law.json"

function NationalSecurityLaw () {
  const { t } = useTranslation()
  const classes = useStyles()
  const [chapter] = useState('')

  return (
    <>
      <Container className={classes.container}>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Typography className={classes.title}>{t('What is the National Security Law?')}</Typography>
            <Grid container spacing={5}>
              <Grid item sm={12} md={6} dangerouslySetInnerHTML={{ __html: t("In 2019, China proposed an extradition bill that could potentially allow Hong Kong suspects to be sent for trial in the mainland. This led to the eruption of widespread pro-democracy, anti-China protests in Hong Kong, inspiring Chinese authorities to tighten control over the territory and reduce its autonomy.") }} />
              <Grid item sm={12} md={6} dangerouslySetInnerHTML={{ __html: t("Aware that the political climate would make passing legislation in Hong Kong impossible, Chinese authorities inserted the National Security Law (NSL) directly into Hong Kong’s constitution on 30 June 2020, bypassing local legislative procedures in breach of Hong Kong’s Basic Law. Ostensibly enacted to bring stability to Hong Kong, the NSL has made it easier for authorities to prosecute protesters and silence opposition to the Chinese establishment rule.") }} />
            </Grid>
          </CardContent>
        </Card>
        <MainBody
          GodObj={nationalSecurityLawObj}
          changeChapter={chapter}
         />
      </Container>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  cardContent: {
    padding: '0 !important',
    "& a": {
      color: 'white',
    },
  },
  card: {
    border: `1px solid ${nationalSecurityLawObj.colorTheme.mainColor}`,
    backgroundColor: 'transparent',
    marginTop: '40px',
    padding: '60px 100px',
    boxShadow: 'none',
    fontFamily: 'Manrope',
    whiteSpace: 'pre-line',
    [theme.breakpoints.down('sm')]: {
      padding: '40px 20px',
    }
  },
  title: {
    fontSize: '22px',
    fontWeight: 700,
    lineHeight: 1.2,
    marginBottom: '32px',
    [theme.breakpoints.down('sm')]: {
      // fontSize: '24px'
    }
  },
  container: {
    marginTop: '130px',
    fontSize: '16px',
    lineHeight: 1.5,
    [theme.breakpoints.down('xs')]: {
      marginTop: '90px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px',
      fontSize: '15px',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '1408px',
    }
  }
}))

export default NationalSecurityLaw
