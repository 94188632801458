import React from "react";
import {
  Container,
  Typography
} from '@material-ui/core'
import { makeStyles } from "@material-ui/core/styles"
import {
  Switch,
  Route
} from "react-router-dom";
import { useTranslation } from 'react-i18next'
import IconDue from "./assets/icon-due.svg"
import IconLegal from "./assets/icon-legal.svg"
import IconMl from "./assets/icon-ml.svg"
import IconNsl from "./assets/icon-nsl.svg"

function Banner(){
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <div>
      <Container>
        <div className={classes.containerStyles}>
          <Switch>
            <Route path='/:lang/national-security-law'>
              <div className={classes.textBox}>
                <Typography className={classes.engText}>{t('National Security Law')}</Typography>
                <div className={classes.icon}><img src={IconNsl} alt={t('National Security Law')} /></div>
              </div>
              <Typography className={classes.descText} dangerouslySetInnerHTML={{ __html: t('Understanding the National Security Law, what offences it covers, who can be charged under it, how it is enforced and the ways in which it has been used since it was introduced is important to ensure you do not fall foul of the legislation.') }} />
            </Route>
            <Route path='/:lang/money-laundering'>
              <div className={classes.textBox}>
                <Typography className={classes.engText}>{t('Money Laundering')}</Typography>
                <div className={classes.icon}><img src={IconMl} alt={t('Money Laundering')} /></div>
              </div>
              <Typography className={classes.descText} dangerouslySetInnerHTML={{ __html: t('Financial transactions are increasingly under strict scrutiny in Hong Kong. Understand the money laundering legislation to ensure you stay on the right side of the law.') }} />
            </Route>
            <Route path='/:lang/due-process'>
              <div className={classes.textBox}>
                <Typography className={classes.engText}>{t('Due Process')}</Typography>
                <div className={classes.icon}><img src={IconDue} alt={t('Due Process')} /></div>
              </div>
              <Typography className={classes.descText} dangerouslySetInnerHTML={{ __html: t('Understand your legal rights and obligations in police encounters and while navigating the criminal justice system in Hong Kong. Know what to expect and act in your best interests if you are stopped, searched, arrested or charged.') }} />
            </Route>
            <Route path='/:lang/legal-aid'>
              <div className={classes.textBox}>
                <Typography className={classes.engText}>{t('Legal aid')}</Typography>
                <div className={classes.icon}><img src={IconLegal} alt={t('Legal aid')} /></div>
              </div>
              <Typography className={classes.descText} dangerouslySetInnerHTML={{ __html: t('Everything you need to know about getting free or subsidised legal advice, representation or other forms of legal assistance in Hong Kong.') }} />
            </Route>
          </Switch>
        </div>
      </Container>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  textBox: {
    width: '50%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      // paddingTop: '90px',
      width: '100%',
    }
  },
  engText: {
    fontFamily: 'Manrope',
    fontWeight: 900,
    fontSize: '45px',
    lineHeight: 1.2,
    color: '#FEFBFA',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      fontSize: '35px',
      width: '100%',
    }
  },
  descText: {
    width: '50%',
    // fontFamily: "'Noto Sans TC', sans-serif",
    fontFamily: 'Manrope',
    fontStyle: 'normal',
    fontSize: '18px',
    lineHeight: '1.4',
    letterSpacing: '-0.01em',
    color: '#FEFBFA',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
      width: '100%',
      marginTop: '40px',
    }
  },
  icon: {
    width: '60px',
    marginTop: '8px',
    '& > img': {
      maxWidth: '100%',
      maxHeight: '100%',
    }
  },
  containerStyles: {
    display: 'flex',
    justifyContent: 'space-around',
    overflow: 'hidden',
    padding: '120px 0 90px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: '70px 0 45px',
    }
  }
}))

export default Banner;
