import React, { useState, useEffect } from 'react'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AppBar,
  Button,
  Collapse,
  Grid,
  Hidden,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Snackbar
} from '@material-ui/core'
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from "react-router-dom"
import gsap from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import debounce from 'lodash.debounce'
import { copyTextToClipboard } from './copyToClipboard'

gsap.registerPlugin(ScrollToPlugin);

function MainBody ({GodObj, changeChapter}) {
  const obj = (() => {
    const ret = {root: 0}
    const map = {}
    const order = ['root']
    GodObj.structure.map(chapter => {
      if (Array.isArray(chapter)) {
        chapter.map(section => {
          const _section = toJsId(section)
          ret[_section] = null
          order.push(_section)
          map[_section] = section
          return null
        })
      } else {
        const _chapter = toJsId(chapter)
        ret[_chapter] = null
        order.push(_chapter)
        map[_chapter] = chapter
      }
      return null
    })
    return {sectionItems: ret, sectionItemsOrder: order, sectionItemsMap: map}
  })()

  const classes = useStyles(GodObj.colorTheme)
  const {t, i18n} = useTranslation()
  const history = useHistory()

  const [navigatingObj, setNavigatingObj] = useState({
    activeSection: 'root',
    showSnackbar: false,
    mobileMenuAnchor: null,
    routed: false,
    ...obj
  })
  const params = useParams()

  const structure = GodObj.structure.map(chapter => {
    if ( Array.isArray(chapter))
      return chapter.map(section => toJsId(section))
    return toJsId(chapter)
  })

  // initiate anchor points dynamically
  const getAnchorPoints = () => {
    const {sectionItems} = navigatingObj
    const curScroll = window.scrollY
    let isChanged = false
    for ( const key in sectionItems ) {
      // prevent calling during unmount
      // if (sectionItems[key] === null) {
        const target = document.getElementById(toJsId(key))
        let v = 1e10
        if ( target )
          v = target.getBoundingClientRect().top + curScroll
        if ( sectionItems[key] !== v) {
          sectionItems[key] = v
          isChanged = true
        }
      //}
    }
    if (isChanged) setNavigatingObj(p => ({ ...p, sectionItems }))
  }

  const handleScroll = () => {
    const curPos = window.scrollY
    let curSection = null
    for (const section of navigatingObj.sectionItemsOrder){
      curSection = ( curPos + (window.innerWidth < 960 ? 161 : 61) ) >= navigatingObj.sectionItems[section] ? section : curSection
      if ( curSection !== section ) {
        break
      }
    }

    if (curSection !== navigatingObj.activeSection) {
      setNavigatingObj(p => ({...p, activeSection: curSection}))
      history.replace( "/"+i18n.language + GodObj.url + (curSection !== 'root' ? '/'+curSection : ''))
    }
  }

  const debouncedScroll = debounce(handleScroll, 200);

  const handleClick = (section) => {
    console.log(section, navigatingObj.sectionItems)
    section = toJsId(section)
    // expand the section
    let sectionDiv = document.getElementById(section);
    if (sectionDiv) {
      const isSub = sectionDiv.classList.contains('MuiAccordionSummary-root')
      if (!isSub) {
        const firstTitle = sectionDiv.parentNode.nextSibling.firstChild
        sectionDiv = firstTitle;
      }
      if (!sectionDiv.classList.contains('Mui-expanded')) {
        sectionDiv.click();
      }
    }

    // close mobile menu
    setNavigatingObj(p => ({...p, mobileMenuAnchor: null}))
    // if user visiting content page root instead of sections on a page
    // set scrollY to 0 without delay, else use scroll animation to transition to target position
    if (sectionDiv === null) {
      gsap.to(window, 0.0, {
        scrollTo: {
          y: 0,
          autoKill: true,
        },
        ease: 'power2.inOut',
      });
    } else {
      gsap.to(window, 0.8, {
        scrollTo: {
          y: navigatingObj.sectionItems[section]-(window.innerWidth < 960 ? 160 : 60),
          autoKill: true,
        },
        ease: 'power2.inOut',
      });
    }
  }

  const handleCopyClick = (section) => {
    setNavigatingObj(p => ({...p, showSnackbar: true}))
    // navigator.clipboard.writeText(window.location.hostname+"/"+i18n.language+GodObj.url+"/"+section)
    copyTextToClipboard(window.location.hostname+"/"+i18n.language+GodObj.url+"/"+section)
  }

  // scroll hide menu
  const [hideNav, setHideNav] = useState(false);
  let prevScrollpos = window.pageYOffset;
  const handleNavScroll = () => {
    const currentScrollPos = window.pageYOffset;
    if (prevScrollpos > currentScrollPos || currentScrollPos < 150) {
      setHideNav(false);
    } else if (currentScrollPos > 150) {
      setHideNav(window.innerWidth < 960);
    }
    prevScrollpos = currentScrollPos;
  }

  useEffect(() => {
    window.addEventListener('scroll', debouncedScroll)
    window.addEventListener('scroll', handleNavScroll)
    window.addEventListener('resize', getAnchorPoints)
    const observer = new MutationObserver(getAnchorPoints)
    observer.observe(document.getElementById('root'), {
      childList: true,
      subtree: true
    })

    // define unmount function
    return () => {
      console.log('cleanup')
      observer.disconnect()
      window.removeEventListener('scroll', debouncedScroll)
      window.removeEventListener('scroll', handleNavScroll)
      window.removeEventListener('resize', getAnchorPoints)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // Dependency [] make cleanup function called only once

  useEffect(() => {
    const anchorLinks = document.getElementsByClassName('js-anchor');
    if (anchorLinks.length > 0) {
      [...anchorLinks].map(item => {
        item.addEventListener('click', function(e) {
          e.preventDefault();
          handleClick(e.target.getAttribute('href').substr(1))
        })
        return false;
      });
    }
  // eslint-disable-next-line
  }, [t('Home')])

  useEffect(() => {
    if (!navigatingObj.routed && navigatingObj.sectionItemsOrder.slice(1).includes(params.section)) {
      handleClick(params.section)
      setNavigatingObj(p => ({...p, routed: true }))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigatingObj])

  useEffect(() => {
    handleClick(changeChapter)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeChapter])

  // const [expanded, setExpanded] = React.useState('panel0');

  // const handleChange = (panel) => (event, newExpanded) => {
    // setExpanded(newExpanded ? panel : false);
  // };

  const handleAccordionChange = () => {
    setTimeout(getAnchorPoints, 1000);
  };

  return (
    <Grid container className={classes.containerStyle} spacing={2}>
      <Hidden smDown>
        <Grid item sm={12} md={4}>
          <List style={IndexStyles}
            component="nav"
            className={classes.list}
          >
            {
              GodObj.structure.map((chapter, chapterIdx) => {
                if ( !Array.isArray(chapter) ) {
                  return (
                    <ListItem button onClick={() => handleClick(toJsId(chapter))} key={'menu-chapter-'+chapterIdx}
                      className={[classes.navChapter,
                        (navigatingObj.activeSection === structure[chapterIdx] || (
                          Array.isArray(structure[chapterIdx+1]) && structure[chapterIdx+1].includes(navigatingObj.activeSection))) ?
                          classes.activeChapter : null].join(' ')
                      }
                    >
                      <ListItemText className={classes.menuChapterTitle} primary={t(chapter)} />
                    </ListItem>
                  )
                } else {
                  return (
                    <Collapse in={true} unmountOnExit key={"menu-chapter-"+chapterIdx}>
                      <List component="div" disablePadding>
                        {
                          chapter.map((section, sectionIdx) => (
                            <ListItem button key={'menu-section-'+sectionIdx} className={classes.nested} onClick={() => handleClick(toJsId(section))}>
                              <ListItemText secondary={t(section)} className={navigatingObj.activeSection === structure[chapterIdx][sectionIdx] ? classes.activeSection : "" } />
                            </ListItem>
                          ))
                        }
                      </List>
                    </Collapse>
                  )
                }
              })
            }
          </List>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <div
          style={Boolean(navigatingObj.mobileMenuAnchor) ? {height: '60px', width: '1px'} : {}}
        />
        <AppBar
          position="sticky"
          className={[
            classes.mobileMenuContainer,
            Boolean(navigatingObj.mobileMenuAnchor) ? 'fixed' : '',
            hideNav ? 'hide' : '',
          ].join(' ')}
        >
          <Button
            endIcon={<ArrowDropDownIcon/>}
            className={classes.mobileMenuBtn}
            onClick={(e) => setNavigatingObj(p => ({...p, mobileMenuAnchor: e.target}))}
          >
            {t(navigatingObj.sectionItemsMap[navigatingObj.activeSection] || navigatingObj.sectionItemsMap[structure[0]])}
          </Button>
          <div>
            <Menu
              keepMounted
              anchorEl={navigatingObj.mobileMenuAnchor}
              open={Boolean(navigatingObj.mobileMenuAnchor)}
              onClose={() => setNavigatingObj(p => ({...p, mobileMenuAnchor: null}))}
              variant='selectedMenu'
              className={[classes.mobileMenu, hideNav ? classes.mobileMenuHide : ''].join(' ')}
            >
              {
                GodObj.structure.map((chapter, chapterIdx) => {
                  if (!Array.isArray(chapter)) {
                    return (
                      <MenuItem
                        key={'mobile-'+chapterIdx}
                        onClick={() => handleClick(chapter)}
                        className={
                          `${classes.mobileMenuChapter} ` +
                          (navigatingObj.activeSection === structure[chapterIdx] || (
                           Array.isArray(structure[chapterIdx+1]) && structure[chapterIdx+1].includes(navigatingObj.activeSection)) ?
                           `${classes.activeMobileMenuChapter}` : "" )
                        }
                      >
                        <ArrowRightIcon className={classes.mobileMenuArrow} />
                        <span>{t(chapter)}</span>
                      </MenuItem>
                    )
                  } else {
                    return chapter.map((section, sectionIdx) => {
                      return (
                        <MenuItem
                          key={'mobile-'+chapterIdx+'-section-'+sectionIdx}
                          onClick={() => handleClick(section)}
                          className={
                            `${classes.mobileMenuSection} `+
                            (navigatingObj.activeSection === structure[chapterIdx][sectionIdx] ? `${classes.activeMobileMenuSection}` : "")
                          }
                        >
                          <span>{t(section)}</span>
                        </MenuItem>
                      )
                    })
                  }
                })
              }
            </Menu>
          </div>
        </AppBar>
      </Hidden>
      <Grid item xs={12} md={8} className={classes.contentColumn}>
        {
          GodObj.content.map((chapter, chapterIdx) => {
            return (
              <div style={{marginBottom: '100px'}} key={'Chapter-'+chapterIdx}>
                <div className={classes.chapterTitleContainer}>
                  <div className={classes.chapterTitle} id={toJsId(chapter.title)} dangerouslySetInnerHTML={{
                    __html: t(chapter.title)
                  }} />
                  <Button className={classes.shareButton} onClick={() => handleCopyClick(toJsId(chapter.title))}>
                    Share this topic&nbsp;&nbsp;&nbsp;&nbsp;
                    <img src="/cp_share.svg" alt="copy this topic" />
                  </Button>
                </div>
                {
                  chapter.sections.map((section, sectionIdx) => {
                    return (
                      <Accordion
                        square
                        defaultExpanded={sectionIdx === 0}
                        // expanded={expanded[`panel${chapterIdx}-${sectionIdx}`] === true}
                        key={'accordion-'+section.title}
                        // onChange={handleChange(`panel${chapterIdx}-${sectionIdx}`)}
                        onChange={handleAccordionChange}
                        className={classes.accordion}
                        aria-controls={`panel${chapterIdx}-${sectionIdx}d-content`}
                      >
                        {
                          section.title ?
                          <AccordionSummary className={`${classes.sectionTitle} ${classes.accordionSummary}`} id={toJsId(section.title)} expandIcon={<ArrowDropDownRoundedIcon className={classes.accordionArrow} />}>
                            {t(section.title)}
                          </AccordionSummary> : null
                        }
                        {
                          section.content.map((body, idx) => {
                            if ( body.type === 'text' ) {
                              return (
                                <AccordionDetails  style={{flexDirection: 'column'}} className={classes.accordionDetails + ' kyrhk-content'} key={"accordiondetails-"+idx}>
                                  { body.header ?
                                    <div className={classes.question} dangerouslySetInnerHTML={{
                                      __html: t(body.header)
                                    }} /> : null
                                  }
                                  <div className={classes.mainBody} dangerouslySetInnerHTML={{
                                    __html: t(body.body)
                                  }} />
                                </AccordionDetails>
                              )
                            } else if ( body.type === 'dual-column-text' ){
                              return (
                                <AccordionDetails className={[classes.accordionDetails, classes.dualColumn, 'kyrhk-content'].join(' ')} key={"accordiondetails-"+idx}>
                                  {
                                    body.content.map(column => (
                                      <div key={column.body.substr(0,10)} className={classes.column}>
                                        <div className={classes.question} dangerouslySetInnerHTML={{
                                          __html: t(column.header)
                                        }} />
                                        <div className={classes.mainBody} dangerouslySetInnerHTML={{
                                          __html: t(column.body)
                                        }} />
                                      </div>
                                    ))
                                  }
                                </AccordionDetails>
                              )
                            } else {
                              console.log(body)
                              return null
                            }
                          })
                        }
                      </Accordion>
                    )
                  })
                }
              </div>
            )
          })
        }
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        autoHideDuration={2000}
        onClose={() => setNavigatingObj(p => ({...p, showSnackbar: false}))}
        open={navigatingObj.showSnackbar}
        message="Copy to clipboard"
      />
    </Grid>
  )
}

const IndexStyles = {
  position: 'sticky',
  top: 0,
  overflow: 'auto'
}

const useStyles = makeStyles((theme) => ({
  containerStyle: {
    marginTop: '80px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '60px'
    }
  },
  list: {
    fontFamily: 'Manrope',
    fontSize: '20px',
    color: '#31323A',
    maxHeight: '100vh',
  },
  menuChapterTitle: {
    '& span': {
      fontWeight: 'bold',
      fontSize: '18px',
    }
  },
  navChapter: {
    padding: '6px 20px 5px 40px',
  },
  activeChapter: {
    background: "#31323A",
    borderRadius: "5px",
    color: "#FEFBFA",
    "&:hover": {
      color: "#31323A"
    }
  },
  activeSection: props => ({
    "& > .MuiListItemText-secondary":{
      fontWeight: 'bold',
      color: props.mainColor
    }
  }),
  nested: {
    padding: '0px 0px 0px 70px',
    fontSize: '18px',
    lineHeight: '140%',
    "& .MuiListItemText-secondary": {
      fontSize: '16px',
      lineHeight: 1.4,
      color: "#59575B"
    },
  },
  chapterTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '30px',
    justifyContent: 'space-between',
    "& .MuiButton-label": {
      textTransform: 'none',
      fontFamily: 'Manrope'
    },
    [theme.breakpoints.down('sm')]:{
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  chapterTitle: props => ({
    fontSize: '30px',
    lineHeight: '40px',
    fontWeight: 800,
    color: "#31323A",
    [theme.breakpoints.down('sm')]:{
      fontSize: '24px',
    },
  }),
  sectionTitle: props => ({
    color: props.mainColor,
    fontSize: '22px',
    fontWeight: 'bold',
    lineHeight: 1.2,
    [theme.breakpoints.down('sm')]:{
      fontSize: '18px',
    },
  }),
  mainBody: props => ({
    whiteSpace: 'pre-line',
    fontSize: '15px',
    color: '#59575B',
    lineHeight: 1.4,
    marginBottom: '50px',
    [theme.breakpoints.down('sm')]:{
      marginBottom: '30px',
    },
    '& strong': {
      color: '#31323A',
    },
    '& h5': {
      fontSize: '18px',
      color: '#31323A',
      margin: '0 0 9px',
    },
  }),
  question: {
    color: '#DB4B38',
    fontSize: '20px',
    lineHeight: '26px',
    fontWeight: 'bold',
    marginBottom: '3.3%'
  },
  column: {
    width: '50%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  dualColumn: {
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
    '& $mainBody': {
      color: '#31323A',
    },
  },
  contentColumn: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: '50px !important',
    },
  },
  shareButton: {
    fontSize: '13px',
    lineHeight: '15px',
    color: '#8C8A8F',
    maxHeight: '30px',
    textTransition: 'none',
    padding: '6px 0px',
    minWidth: '136px',
    '& > .MuiButton-label': {
      whiteSpace: "nowrap",
    },
    [theme.breakpoints.down('sm')]: {
      '& > .MuiButton-label': {
        flexDirection: 'row-reverse',
        "& > img": {
          marginRight: '15px'
        }
      },
      marginTop: '15px'
    }
  },
  mobileMenuContainer: {
    width: '100vw',
    marginLeft: '-16px',
    top: '90px',
    transition: 'top 0.5s ease-in-out',
    [theme.breakpoints.down('xs')]:{
      marginLeft: '-8px'
    },
    '&.fixed': {
      position: 'fixed',
    },
    '&.hide': {
      top: 0,
    },
  },
  mobileMenuBtn: props => ({
    justifyContent: 'space-between',
    height: '60px',
    color: '#FEFBFA',
    background: '#31323A',
    padding: '0px 20px',
    fontSize: '18px',
    textTransform: 'none',
    '&:active': {
      backgroundColor: '#383C45'
    },
    '&:hover': {
      backgroundColor: '#383C45'
    }
  }),
  mobileMenu: props => ({
    "& > .MuiMenu-paper": {
      top: '150px !important',
      left: '0px !important',
      background: props.mobileMenuBackground, //'#80C5C5',
      borderRadius: '0px 0px 30px 30px',
      boxShadow: '4px 4px 30px 5px rgba(0, 0, 0, 0.25)',
      border: '0px solid '+props.mobileMenuBorder,
      width: '100vw',
      maxWidth: '100vw',
      maxHeight: '82vh',
      overflow: "hidden",
      overflowY: 'auto',
    }
  }),
  mobileMenuHide: props => ({
    "& > .MuiMenu-paper": {
      top: '60px !important',
    },
  }),
  mobileMenuChapter: {
    marginTop: '20px',
    marginLeft: '15px',
    fontSize: '18px',
    lineHeight: '21px',
    fontWeight: 900,
    color: '#FEFBFA'
  },
  activeMobileMenuChapter: {
    color: '#31323A !important',
    '& > svg': {
        color: '#31323A',
    }
  },
  activeMobileMenuSection: {
    color: '#31323A !important',
    "& > span:first-child": {
      borderBottom: '1px solid #31323A',
      fontWeight: 900
    }
  },
  mobileMenuSection: {
    fontSize: '16px',
    lineHeight: '140%',
    color: '#FEFBFA',
    marginLeft: '30px'
  },
  mobileMenuArrow:{
    marginLeft: '-26px',
    color: 'transparent'
  },
  // Accordion
  accordion: props => ({
    border: '1px solid '+props.mainColor,
    boxShadow: 'none',
    '&:nth-of-type(2)': {
      borderTopRightRadius: '10px',
      borderTopLeftRadius: '10px'
    },
    '&:last-of-type': {
      borderBottomRightRadius: '10px',
      borderBottomLeftRadius: '10px'
    },
    '&:not(:last-of-type)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none'
    },
    '&.Mui-expanded': {
      margin: '0'
    },
    '& > .MuiCollapse-container .MuiCollapse-wrapperInner': {
      paddingTop: '0px',
      paddingBottom: '0px',
    }
  }),
  accordionSummary: props => ({
    paddingLeft: '7%',
    borderBottom: '1px solid rgba(255,255,255,0)',
    transition: 'border 0.5s',
    [theme.breakpoints.down('sm')]:{
      minHeight: 0,
      height: '50px',
    },
    '& .MuiAccordionSummary-content': {
      margin: '20px 0',
      [theme.breakpoints.down('sm')]:{
        margin: '10px 0',
      },
    },
    '&.Mui-expanded': {
      borderBottom: '1px solid '+props.mainColor,
      [theme.breakpoints.down('sm')]:{
        minHeight: 0,
        height: '50px',
      },
    }
  }),
  accordionArrow: props => ({
    color: props.mainColor,
    width: '1.5em',
    height: '1.5em'
  }),
  accordionDetails: props => ({
    padding: '40px 7% 0px 7%',
    '& a': {
      color: props.mainColor,
      fontWeight: 900,
      textDecoration: 'none',
      borderBottom: '1px solid'
    },
    '&:first-child': {
      paddingTop: '50px'
    },
    [theme.breakpoints.down('sm')]:{
      padding: '30px 7% 0px 7%',
      '&:first-child': {
        paddingTop: '30px !important'
      },
    },
  })
}))

const toJsId = (str) => {
  return str.replaceAll(' ', '-').replaceAll('&', '-').replaceAll('’', '').toLowerCase()
}

export default MainBody
