import React from 'react'
import {
  Card,
  CardContent,
  Container,
  Grid,
  Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import IconMl from "./assets/icon-ml.svg"
import IconNsl from "./assets/icon-nsl.svg"
import IconDue from "./assets/icon-due.svg"
import IconLegal from "./assets/icon-legal.svg"
import ArrowBlue from "./assets/arrow-blue.svg"
import ArrowTeal from "./assets/arrow-teal.svg"
import ArrowPurple from "./assets/arrow-purple.svg"
import ArrowOrange from "./assets/arrow-orange.svg"

function Home () {
  const { t, i18n } = useTranslation()
  const classes = useStyles()

  return (
    <Container className={classes.container}>
      <Grid container spacing={1}>
        <Grid item sm={6} md={6}>
          <Link to={`/${i18n.language}/money-laundering`} className={classes.link}>
            <Card className={[classes.card, classes.moneyLaundering].join(' ')}>
              <span />
              <CardContent>
                <div className={classes.topRow}>
                  <div className={classes.icon}><img src={IconMl} alt="Understanding HK\'s Laws" /></div>
                  <span className={classes.arrow}><img src={ArrowBlue} alt='Money laundering' /></span>
                </div>
                <h2 className={classes.title}>{t('Understanding HK\'s Laws')}</h2>
                <Typography className={classes.description}>{t('Hong Kong’s lawbook can be complicated and overwhelming, and the ways in which existing legislation is being used are changing as the political landscape in the city shifts. Activities that were once benign, such as crowdfunding, may now carry risks.\n\nIt is important you stay vigilant and aware of how the application of these laws evolve. Understanding the nuances and implications of specific legislation can help you stay on the right side of the law.')}</Typography>
              </CardContent>
            </Card>
          </Link>
        </Grid>
        <Grid item sm={6} md={6}>
          <Link to={`/${i18n.language}/due-process`} className={classes.link}>
            <Card className={[classes.card, classes.dueProcess].join(' ')}>
              <span />
              <CardContent>
                <div className={classes.topRow}>
                  <div className={classes.icon}><img src={IconDue} alt="Due Process" /></div>
                  <span className={classes.arrow}><img src={ArrowTeal} alt='Due Process' /></span>
                </div>
                <h2 className={classes.title}>{t('Due Process')}</h2>
                <Typography className={classes.description}>{t('What is your experience with police encounters in Hong Kong? Are you aware of your rights in a search or an arrest? Has someone you care about been charged with an offence? \n\nDue process is a requirement on authorities to guarantee protections of legal rights of the individuals, and the proper application of law. \n\nYou have rights throughout the process. Understand them, exercise them, and know what your options are if you feel that your civil liberties and fundamental rights are at risk.')}</Typography>
              </CardContent>
            </Card>
          </Link>
        </Grid>
        <Grid item sm={6} md={6}>
          <Link to={`/${i18n.language}/legal-aid`} className={classes.link}>
            <Card className={[classes.card, classes.legalAid].join(' ')}>
              <span />
              <CardContent>
                <div className={classes.topRow}>
                  <div className={classes.icon}><img src={IconLegal} alt="Legal Aid" /></div>
                  <span className={classes.arrow}><img src={ArrowPurple} alt='Legal Aid' /></span>
                </div>
                <h2 className={classes.title}>{t('Legal Aid')}</h2>
                <Typography className={classes.description}>{t('Are you in need of legal assistance in Hong Kong? You have the right to legal advice and representation, and this right shouldn’t be hampered by costs. \n\nAccess to justice is more than protecting individual interests in legal proceedings. Our society depends on it - an accessible legal system enables the public to trust the courts to administer law in a fair and just manner.\n\nIn reality, navigating the judicial system can be a daunting experience, and seeking expert legal advice and engaging legal representation can be expensive. If you don’t have the means, there are various platforms and schemes in Hong Kong that may be available to you. Find out which one is right for you.')}</Typography>
              </CardContent>
            </Card>
          </Link>
        </Grid>
        <Grid item sm={6} md={6}>
          <Link to={`/${i18n.language}/national-security-law`} className={classes.link}>
            <Card className={[classes.card, classes.nationalSecurityLaw].join(' ')}>
              <span />
              <CardContent>
                <div className={classes.topRow}>
                  <div className={classes.icon}><img src={IconNsl} alt="Hong Kong’s National Security Law" /></div>
                  <span className={classes.arrow}><img src={ArrowOrange} alt='Security Law' /></span>
                </div>
                <h2 className={classes.title}>{t('National Security Law')}</h2>
                <Typography className={classes.description}>{t('When China directly inserted its National Security Law into Hong Kong’s constitution in June 2020, the city’s legal and political environment was transformed.\n\nAuthorities claim the legislation has been critical in bringing stability and peace to Hong Kong following anti-China protests in 2019. The sweeping powers it has given to authorities, however, have helped tighten freedom of speech and silence opposition to establishment rule.\n\nUnderstanding the NSL, what offences it covers, who can be charged under it, how it is enforced and the ways in which it has been used since it was introduced is important to ensure you do not fall foul of the legislation.')}</Typography>
              </CardContent>
            </Card>
          </Link>
        </Grid>
        
      </Grid>
    </Container>
  )
}

const useStyles = makeStyles(theme => ({
  card: {
    position: 'relative',
    background: '#31323A',
    marginTop: '0',
    padding: '90px 56px',
    color: '#FEFBFA',
    fontFamily: 'Manrope',
    whiteSpace: 'pre-line',
    borderRadius: 0,
    [theme.breakpoints.down('sm')]: {
      padding: '40px 20px 30px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '80px 24px',
    },
    '& .MuiCardContent-root': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '0px'
    },
    '& > span': {
      display: 'block',
      position: 'absolute',
      top: 0,
      left: '50%',
      width: '72%',
      height: '10px',
      background: '#2E9999',
      transform: 'translateX(-50%)',
      transition: 'width 0.6s ease-in-out',
      [theme.breakpoints.down('xs')]: {
        left: '0%',
        width: '100%',
        transform: "initial",
        height: '6px'
      },
    }
  },
  topRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%'
  },
  icon: {
    maxWidth: '60px',
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
    },
  },
  description: {
    margin: '25px 0',
    fontSize: '16px',
    lineHeight: 1.5,
    transition: 'color 0.8s',
    [theme.breakpoints.down('sm')]: {
      margin: '20px 0',
      fontSize: '14px',
    },
  },
  cta: {
    margin: '30px 0',
    textTransform: 'uppercase',
  },
  linkText: {
    display: 'inline-block',
    verticalAlign: 'middle',
    fontSize: '16px',
    fontWeight: 'bold',
    letterSpacing: '0.1em',
    maxWidth: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    transition: 'all 0.8s ease-in-out',
  },
  arrow: {
    display: 'inline-block',
    verticalAlign: 'middle',
    padding: '0px',
    lineHeight: 0.8,
  },
  dueProcess: {
    '& $linkText': {
      color: '#2E9999'
    }
  },
  legalAid: {
    '& > span': {
      background: '#857BC6',
    },
    '& $linkText': {
      color: '#857BC6'
    }
  },
  nationalSecurityLaw: {
    '& > span': {
      background: '#C99639',
    },
    '& $linkText': {
      color: '#C99639'
    }
  },
  moneyLaundering: {
    '& > span': {
      background: '#3F82E0',
    },
    '& $linkText': {
      color: '#3F82E0'
    }
  },
  title: {
    textAlign: 'left',
    width: '100%',
    fontWeight: 700,
    fontSize: '30px',
    lineHeight: 1.2,
    marginTop: '40px',
    marginBottom: '0px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px'
    }
  },
  container: {
    padding: '100px 24px',
    [theme.breakpoints.down('sm')]: {
      padding: '60px 16px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0px',
    },
    '& > .MuiGrid-container > .MuiGrid-item': {
      padding: '20px 10px',
      [theme.breakpoints.down('xs')]: {
        padding: '0px',
      },
    }
  },
  link: {
    display: 'block',
    textDecoration: 'none',
    height: '100%',
    '& > div': {
      height: '100%',
      transition: 'all 0.3s',
    },
    '&:hover': {
      '& > div > span': {
        width: '100%',
      },
      '& $dueProcess': {
        background: '#494B55',
        '& $description': {
          color: '#E0DEF2'
        }
      },
      '& $legalAid': {
        background: '#5E616E',
        '& $description': {
          color: '#E0DEF2'
        }
      },
      '& $nationalSecurityLaw': {
        background: '#5E616E',
        '& $description': {
          color: '#E0DEF2'
        }
      },
      '& $moneyLaundering': {
        background: '#494B55',
        '& $description': {
          color: '#E0DEF2'
        }
      },
      '& $linkText': {
        maxWidth: '200px',
      },
    }
  }
}))

export default Home
